<template>
  <v-content>
    <v-container grid-list-lg fluid>
      <v-layout wrap align-top justify-left fill-height>
        <v-flex xs12 lg12 xl12>
          <v-card>
            <title-card>
              <template slot="title">
                <span v-text="`${item.id > 0 ? 'Editar cupon' : 'Nuevo Cupon'}`"></span>
                <small class="ml-2 caption" v-text="`${item.id > 0 ? '(' + item.code + ')' : ''}`"></small>
              </template>
            </title-card>
            <v-divider></v-divider>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.name" label="Nombre" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.code" label="Codigo" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.from" type="date" label="Valido desde" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.end" type="date" label="Expira el.." hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.min" label="Monto minimo" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.total" label="Total disponibles" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-radio-group v-model="item.type" row hide-details>
                    <v-radio color="secondary" label="Porcentaje" :value="0" hide-details></v-radio>
                    <v-radio color="secondary" label="Monto" :value="1" hide-details></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-text-field v-model="item.value" label="Descuento" hide-details></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 lg4 xl3>
                  <v-switch v-model="item.free" color="secondary" label="Despacho gratis"> </v-switch>
                </v-flex>
                <v-flex v-if="item.user_send !== undefined" xs12 sm6 lg4 xl3>
                  <template v-if="item.user_send.id">
                    <v-subheader>Cupon enviado a:</v-subheader>
                    <v-btn
                      v-if="item.user_send.id"
                      :to="{
                        name: 'buyerDetail',
                        params: { id: item.user_send.id }
                      }"
                      color="info"
                      depressed
                      small
                    >
                      <v-icon class="mr-2" left small>mail</v-icon>
                      <span v-text="item.user_send.name"></span>
                    </v-btn>
                  </template>
                </v-flex>
                <v-flex v-if="item.user_only !== undefined" xs12 sm6 lg4 xl3>
                  <template v-if="item.user_only.id">
                    <v-subheader>Solo lo puede utilizar: </v-subheader>
                    <v-btn
                      :to="{
                        name: 'buyerDetail',
                        params: { id: item.user_only.id }
                      }"
                      color="info"
                      depressed
                      small
                    >
                      <v-icon class="mr-2" left small>mail</v-icon>
                      <span v-text="item.user_only.name"></span>
                    </v-btn>
                  </template>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" depressed @click="saveCoupon(item)">Actualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { COUPON } from '../../config'
import titleCard from '../useful/titleCard.vue'

export default {
  name: 'EditDiscountCoupons',
  components: {
    titleCard
  },
  props: ['id'],
  data() {
    return {
      config: [],
      item: {
        value: 15,
        type: 0,
        min: 20000,
        from: new Date().toISOString().substr(0, 10),
        end: new Date().toISOString().substr(0, 10)
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  watch: {
    id() {
      this.getDiscount()
    }
  },
  mounted() {
    this.getDiscount()
  },
  methods: {
    async getDiscount() {
      try {
        if (this.id > 0) {
          const res = await this.$http.get(`${COUPON}/${this.id}`)
          this.item = res.data
        }
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveCoupon(item) {
      try {
        await this.$http({
          method: item.id ? 'put' : 'post',
          url: item.id ? `${COUPON}/${this.item.id}` : COUPON,
          data: {
            p: 'saveCouponDiscount',
            item: item
          }
        })
        this.$router.push({
          name: 'editDiscountCoupons',
          params: { id: this.item.id }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
